























































import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import patologiaModule from "@/store/modules/patologia-module";
import { TipoPatologia } from "@/utils/TipoPatologia";
import { patologia } from "@/shared/dtos/patologia";
import { UtilsString } from "@/utils/utils-string";
@Component({
  components: {
    CompHeader: () => import("./CompHeaderLabel.vue"),
    CompChartMacros: () => import("./CompChartMacros.vue")
  }
})
export default class CompObjetivoTratamiento extends Vue {
  @PropSync("objetivo", { type: String }) syncedestilo!: string;
  @PropSync("id_objetivo", { type: Number }) syncedid_objetivo!: number;
  @Prop() index!: number;
  private dialog: boolean = false;
  public estiloSeleccionado: any = {
    text: "",
    value: -1
  };
  public async created() {
    await patologiaModule.getpatologiasFromTipo(
      TipoPatologia.ObjetivoTratamiento
    );
  }
  public get estilos() {
    let valores: any[] = [];
    for (
      let i = 0;
      i < patologiaModule.patologiasObjetivoTratamiento.length;
      i++
    ) {
      const element = patologiaModule.patologiasObjetivoTratamiento[i];
      valores.push({
        text: element.nombre_patologia,
        value: element.id
      });
      if (this.syncedestilo === element.nombre_patologia) {
        this.estiloSeleccionado = valores[valores.length - 1];
        this.$emit("validado", { index: this.index, valido: true });
      }
    }
    return valores;
  }
  @Watch("estiloSeleccionado")
  public ChangeEstilo() {
    for (
      let i = 0;
      i < patologiaModule.patologiasObjetivoTratamiento.length;
      i++
    ) {
      const element = patologiaModule.patologiasObjetivoTratamiento[i];
      if (element.id === this.estiloSeleccionado) {
        this.syncedestilo = element.nombre_patologia;
        this.syncedid_objetivo = element.id;
        this.$emit("validado", { index: this.index, valido: true });
      }
    }
  }
  public get ItemSelect(): patologia {
    for (
      let i = 0;
      i < patologiaModule.patologiasObjetivoTratamiento.length;
      i++
    ) {
      const element = patologiaModule.patologiasObjetivoTratamiento[i];
      if (this.syncedestilo === element.nombre_patologia) {
        this.$emit("validado", { index: this.index, valido: true });
        return element;
      }
    }
    return new patologia();
  }
  public get Descripcion() {
    return (
      UtilsString.cortarStringLargo(
        UtilsString.ValueOf(this.ItemSelect.descripcion_patologia),
        this.$vuetify.breakpoint.xsOnly ? 300 : 400
      ) + "..."
    );
  }
}
